
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class PostInfoImg2Col extends Vue {
  /* Properties */
  @Prop() shouldHaveGreyBackground!: boolean;
  @Prop() imagePath1!: string;
  @Prop() imagePath2!: string;
  @Prop() imageAltText1!: string;
  @Prop() imageAltText2!: string;
  @Prop() shouldRemoveBorder!: boolean;
  /* Store Actions */
  /* Watchers */
  /* Data */
  url1: string = '';
  url2: string = '';
  fallbackUrl: string = `${process.env.VUE_APP_IMAGES_URL}/J1l-Logo1.svg`;
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  imgSrc1() {
    return `${process.env.VUE_APP_IMAGES_URL}/${this.imagePath1}`;
  }
  imgSrc2() {
    return `${process.env.VUE_APP_IMAGES_URL}/${this.imagePath2}`;
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    this.url1 = this.imgSrc1();
    this.url2 = this.imgSrc2();
  }
  /* Created */
  /* Emmited Functions */
}
